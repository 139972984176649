import $ from 'jquery';
import { gsap } from 'gsap';
import MvSlide from '../Mv/_Slide.js';

(() => {
  const op = function () {
    const mar1 = 0;
    const tl = gsap.timeline();

    tl.fromTo(
      '.ko-op__img1',
      {
        x: mar1,
        scale: 1.3,
        opacity: 0,
      },
      {
        x: 0,
        scale: 1,
        opacity: 1,
        ease: 'expo.out',
        duration: 1,
      },
    )
      .fromTo(
        '.ko-op__img2',
        {
          x: mar1,
          scale: 1.3,
          opacity: 0,
        },
        {
          x: 0,
          scale: 1,
          opacity: 1,
          ease: 'expo.out',
          duration: 1,
          delay: 0.5,
        },
      )
      .fromTo(
        '.ko-op',
        {
          opacity: 1,
        },
        {
          opacity: 0,
          ease: 'expo.out',
          duration: 1,
          delay: 0.5,
          onStart: function () {
            op2();
            // onComplete: function () {
            // opFirstEnd();
            // new MvSlide();
          },
          onComplete: function () {
            $('.ko-op').hide();
          },
        },
      );
  };

  const op2 = function () {
    const wh = $(window).height();
    const l1 = $('.ko-topMv__colLeft').width();

      gsap.fromTo('.ko-topMv__colLeft', { x: -l1 }, { x: 0, duration: 1, ease: 'expo.out' });

    if (window.matchMedia( "(max-width: 768px)" ).matches) {
      const h1 = $('.ko-topMv__slideSp1 .swiper-slide').height() * 6;
      gsap.fromTo('.ko-topMv__slideSp1 .swiper-wrapper', { y: -h1 }, { y: 0, duration: 3, ease: 'expo.out', delay: 0.5 });
      gsap.fromTo('.ko-topMv__slideSp2 .swiper-wrapper', { y: wh }, { y: 0, duration: 3, ease: 'expo.out', delay: 0.5, onComplete: function () {
        new MvSlide();
        },
      });

    } else {
      const h1 = $('.ko-topMv__slide1 .swiper-slide').height() * 4;
      gsap.fromTo('.ko-topMv__slide1 .swiper-wrapper', { y: -h1 }, { y: 0, duration: 3, ease: 'expo.out', delay: 0.5 });
      gsap.fromTo('.ko-topMv__slide2 .swiper-wrapper', { y: wh }, { y: 0, duration: 3, ease: 'expo.out', delay: 0.5 });
      gsap.fromTo('.ko-topMv__slide3 .swiper-wrapper', { y: -h1 }, { y: 0, duration: 3, ease: 'expo.out', delay: 0.5, onComplete: function () {
        new MvSlide();
        },
      });
    }

    gsap.fromTo('.ko-topMv__colRight h3', { opacity: 0 }, { opacity: 1, duration: 2, delay: 1.5 });
  };

  if($('#ko-pageId--top').length) {
    // console.log('トップ：MVアニメーション');
    op();
  }
})();
